import React, {Component} from "react"

class IntroHostingBgWhite extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        const {title, subtitle, showPromos} = this.props
        return (
            <section id="packages-intro">
                <div className="container-fluid pt-30">
                    <div className="container">

                        <div className="section-title section-title-800">
                            <h2 className=" h3-title">{title}</h2>
                            <p className="">{subtitle}</p>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default IntroHostingBgWhite
