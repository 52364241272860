import React, { Component } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ReactTooltip from "react-tooltip"
import { Link } from "gatsby"

class IntroHostingPlansParams extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isClicked: false,
      promoCode: "JV89GSYI7K"
    }

  }

  showPromoCode() {
    this.setState({ isClicked: true })
  }

  render() {
    const { isClicked, promoCode } = this.state
    const { title, subtitle, beforePromo, contactButton, buttonText } = this.props
    const showContact = { contactButton }
    return (
      <section id="packages-intro">
        <div className="packages-area hosting-plans-intro container-fluid pt-100">
          <div className="container">
            <div className="section-title section-title-800">
              <h2 className="text-white h3-title">{title} </h2>
              <p className="text-white">{subtitle}</p>
            </div>
            <div className="buttons-row row mb-5 justify-content-center">
              <div className="col-md-12 col-lg-6 text-right mb-3 mx-auto">
                <a className="accent-btn-standard d-inline-flex align-items-center w-100 justify-content-center">Save 6
                  Months / Annual Payment
                  <i className="bx bx-money ml-1" />
                </a>
              </div>
              {/*<div className="col-md-12 col-lg-6 text-left mb-3">*/}
              {/*  <a className="sm-white-btn-outline d-inline-flex align-items-center w-100 justify-content-center"*/}
              {/*     onClick={() => this.showPromoCode()}>TRY 1 MONTH FREE*/}
              {/*    <i className="bx bx-right-arrow-alt ml-1" />*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
            {/*<div className={`row ${isClicked ? "d-block" : "d-none"}`}>*/}
            {/*  <div className="col-12">*/}
            {/*    <div className="promo text-white text-center mb-5">*/}
            {/*      {beforePromo}*/}
            {/*      <span className="promo-code mx-2">*/}
            {/*          <CopyToClipboard text={promoCode}>*/}
            {/*            <span data-tip="Copied!">{promoCode} <i className="bx bx-copy-alt" /> </span>*/}
            {/*          </CopyToClipboard>*/}
            {/*          <ReactTooltip place="top" type="success" effect="solid" textColor="white"*/}
            {/*                        backgroundColor="#17992C"*/}
            {/*                        event="click" isCapture={true} />*/}
            {/*        </span> before finishing the order. Enjoy your 1 month for FREE!*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={`pb-100 row ${showContact.contactButton === "true" ? "d-block" : "d-none"}`}>
              <div className={"col-12 contact-link-container"}>
                <Link to="/contact/" className="sm-white-btn-outline d-block width-300">{buttonText}</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default IntroHostingPlansParams