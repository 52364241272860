import React from "react"
import antivirusIcon from "../../assets/images/sistem-antivirus.png"
import notifIcon from "../../assets/images/sistem-notificari.png"
import servIcon from "../../assets/images/servicii-web.png"
import lansIcon from "../../assets/images/lansare-rapida.png"

const DedicatedServers = () => {
  return (
    <section id="dedicated-servers-info">
      <div className="container-fluid">
        <div className="title-row mb-3">
          <h2 className="h3-title text-center mb-4">Enjoy the benefits of a dedicated hosting server provided by
            SiteBunker.</h2>
          <p>Our dedicated hosting server subscriptions may include additional licenses and services at extra costs. You
            can opt in for these services and enjoy a better response time for your website.</p>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3 dedicated-op">
            <img src={lansIcon} loading="lazy" alt="" />
            <h3 className="mb-3">Quick launch</h3>
            <p>All SiteBunkers’ dedicated hosting servers are launched within a maximum of 24 hours. Contact the
              customer support team for complete details.</p>
          </div>
          <div className="col-md-6 col-lg-3 dedicated-op">
            <img src={notifIcon} loading="lazy" alt="" />
            <h3 className="mb-3">Remote Access</h3>
            <p>The access to the dedicated ILO/IPMI is guaranteed, so that you can enjoy easy server management (start /
              stop / restart / etc).</p>
          </div>
          <div className="col-md-6 col-lg-3 dedicated-op">
            <img src={antivirusIcon} loading="lazy" alt="" />
            <h3 className="mb-3">Premium Software</h3>
            <p>You can order your extra licenses for cPanel, WHM, Cloudlinux needed for hosting server management.</p>
          </div>
          <div className="col-md-6 col-lg-3 dedicated-op">
            <img src={servIcon} loading="lazy" alt="" />
            <h3 className="mb-3">24/7 Support</h3>
            <p>At SiteBunker you have technical support provided based on ticket / email. You can contact us by phone
              from Monday to Friday between 9.00 and 17.00.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DedicatedServers
