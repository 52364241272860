import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import InfoBlocks from "../components/Index/InfoBlocks"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import DedicatedServers from "../components/HostingPlans/DedicatedServers"
import {Link} from "gatsby"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import IntroHostingPlansParams from "../components/HostingPlans/IntroHostingPlansParams"
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";

const ServereDedicate = () => {
    return (
        <Layout>
            <SEO title="Dedicated Hosting Server - Sitebunker.net - Enjoy your hosting "
                 description="Find out here details about the dedicated hosting server provided by ServerBunker. Contact us now for complete server options details."
            />
            <Navbar/>

            <IntroHostingBgWhite
                title={'You get Dedicated Server with unlimited traffic and premium resources'}
                subtitle={'Choose the Server that suits your needs and get 24/7 support, remote access and high uptime at TIER 3 level.'}
            />

            <div className="serv-dedic">

                <HostingPlanBlock
                    category="Servere dedicate EN"
                    splitTitle="yes"
                    showPromos={false}/>
                <BannerArea
                    pageTitle="Dedicated hosting server"
                    pageSubtitle="High performance processors, efficient storage"
                    pageDescription="Now it's easy to have your own dedicated hosting server. Find out details below and discover the dedicated hosting server subscriptions available at SiteBunker."
                    svgIllustration="server-dedicated"
                />
            </div>
            <div className="container-fluid">
                <div className="serv-contact">
                    <h3 className="mb-3">Looking for a personalized dedicated hosting server?</h3>
                    <Link to="/contact/" className="filled-button">
                        Contact us here for a customized solution!
                    </Link>
                </div>
            </div>
            <DedicatedServers/>
            <div className="remove-top-padding">
                <InfoBlocks/>
            </div>
            <ClientFeedback
                title={"SiteBunker customers review:"}/>
            <Footer/>
        </Layout>
    )
}

export default ServereDedicate
