import React, { Component } from "react"
import "../../assets/css/Common/PageBanner.style.css"

import webImg from "../../assets/images/new-imgs/home-banner.png"
import magentoImg from "../../assets/images/new-imgs/magento-big.png"
import wordpressImg from "../../assets/images/new-imgs/wordpress-big.png"
import prestashopImg from "../../assets/images/new-imgs/prestashop-big.png"
import resellerImg from "../../assets/images/new-imgs/reseller-big.png"
import vpsImg from "../../assets/images/new-imgs/vps-big.png"
import serversImg from "../../assets/images/new-imgs/servers-big.png"


class BannerArea extends Component {
  constructor(props) {
    super(props)
  }

  svgSwitch(svgName) {
    switch (svgName) {
      case "magento" :
        return <img src={magentoImg} alt="Hosting Magento" loading="lazy" />
      case "web-ssd" :
        return <img src={webImg} alt="Web Hosting" loading="lazy" />
      case "wordpress" :
        return <img src={wordpressImg} alt="Hosting Wordpress" loading="lazy" />
      case "prestashop" :
        return <img src={prestashopImg} alt="Hosting PrestaShop" loading="lazy" />
      case "reseller" :
        return <img src={resellerImg} alt="Reseller" loading="lazy" />
      case "server-vps" :
        return <img src={vpsImg} alt="VPS Servers" loading="lazy" />
      case "server-dedicated" :
        return <img src={serversImg} alt="Dedicated Servers" loading="lazy" />
    }
  }

  render() {


    const {
      pageSubtitleSecond,
      pageTitle,
      pageSubtitle,
      pageDescription,
      pageDescriptionTwo,
      backgroundImg,
      svgIllustration,
      logo
    } = this.props

    return (
      <div id="bannerArea">
        <div className="background-holder">
          <div className="container">
            <div className="page-title-content">
              <h2 className="mb-4">{pageTitle}</h2>
            </div>
            <div className="page-info-content">
              <h3 className="mb-4">{pageSubtitle}</h3>
              <div className="light-speed-img">
                {logo ? <img src={logo} alt="banner" loading="lazy" /> : ""}
              </div>
              <h3 className="mb-4">{pageSubtitleSecond}</h3>
              <p>{pageDescription}</p>
              <p>{pageDescriptionTwo}</p>
            </div>
          </div>
          {this.svgSwitch(svgIllustration)}
        </div>
      </div>
    )
  }

}

export default BannerArea
